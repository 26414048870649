









import { Component, Mixins } from 'vue-property-decorator'

import BenefitPrograms from '../../../loyalty/organisms/BenefitPrograms/BenefitPrograms.vue'

import { AbstractStep } from '../AbstractStep'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component({
  name: 'BenefitsStep',
  components: { BenefitPrograms }
})
export class BenefitsStep extends Mixins<AbstractStep>(AbstractStep) {
  public errors: string[] | null = null
  public selectedProgram: string[] | null = null

  public onSelected (program: string[]):void {
    this.selectedProgram = program
  }

  public async submit (): Promise<void> {
    if (!this.selectedProgram) {
      this.errors = [this.$t('front.checkout.organisms.BenefitsStep.selectOne').toString()]

      return
    }

    this.nextStep()
  }
}

export default BenefitsStep
